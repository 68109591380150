define("discourse/plugins/discourse-restore-pm-mentions/discourse/initializers/restore-admin-mentions", ["exports", "discourse/lib/plugin-api", "@ember/service", "@ember/template"], function (_exports, _pluginApi, _service, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initWithApi(api) {
    const siteSettings = api.container.lookup("site-settings:main");
    if (!siteSettings.restore_pm_mentions_enabled) return;

    // Modificar el comportamiento del componente `composer-editor`
    api.modifyClass("component:composer-editor", {
      pluginId: "discourse-restore-pm-mentions",
      // Sobrescribir el método _warnCannotSeeMention
      _warnCannotSeeMention() {
        if (!this.warnedCannotSeeMentions) {
          this.warnedCannotSeeMentions = [];
        }
        this.warnedCannotSeeMentions.push("admins");
        this.warnedCannotSeeMentions.push("administrators");
        return this._super(...arguments);
      }
    });

    // Modificar el comportamiento del controlador `composer`
    api.modifyClass("controller:composer", {
      pluginId: "discourse-restore-pm-mentions",
      dialog: (0, _service.inject)(),
      async save() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }
        const _this = this;
        const _super = this._super;

        // Permitir que el personal guarde sin restricciones
        if (this.currentUser.staff) {
          return this._super(...args);
        }
        let mentioned = 0;

        // Buscar menciones al grupo en el contenido del editor
        $(".d-editor-preview a.mention-group").each(function () {
          const group = $(this).text().toLowerCase().trim();
          if (group === "@admins" || group === "@administrators") {
            mentioned++;
          }
        });

        // Si no se menciona a ningún grupo relevante, guardar directamente
        if (!mentioned) {
          return this._super(...args);
        }

        // Confirmar antes de continuar
        return this.dialog.confirm({
          message: (0, _template.htmlSafe)(I18n.t("restore_pm_mentions.alert")),
          shouldDisplayCancel: false,
          confirmButtonLabel: "restore_pm_mentions.proceed",
          confirmButtonClass: "btn-danger",
          didConfirm: async () => {
            // Enviar notificación explícita al grupo si se menciona
            const groupUserMention = api.container.lookup("service:group-user-mention");
            await groupUserMention.notifyGroup("admins", this.topic);
            return _super.call(_this, ...args);
          },
          cancelButtonLabel: "restore_pm_mentions.back",
          cancelButtonClass: "btn-default",
          didCancel: () => {
            // Operación cancelada
          }
        });
      }
    });
  }
  var _default = _exports.default = {
    name: "restore-admin-mentions",
    initialize() {
      (0, _pluginApi.withPluginApi)("3.0.0", initWithApi); // Compatibilidad con API 3.0.0 o superior
    }
  };
});